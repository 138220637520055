.home-page{
    .text-zone{
        position:absolute;
        left: 10%;
        top: 40%;
        transform: translateY(-50%);
        width: 60%;
        max-height: 90%;
    }

    h1{
        color:#fff;
        font-size: 52px;
        line-height: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before{
            content:'<introduction>';
            font-family: 'La Belle Aurore';
            color:#F5D061;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after{
            content:'</introduction>';
            font-family: 'La Belle Aurore';
            color:#F5D061;
            font-size: 18px;
            position: absolute;
            margin-top: 30px;
            margin-left: 60px;
            opacity: 0.6;
            animation: fadeIn;            
        }

        .imgR{
            width: 32px;
            margin-left: 0px;
            opacity: 0;
            height: auto;
            animation: animate-slide-down .75s linear both;
            animation-delay: 1s;
            animation-name: animate-slide-down;
            @keyframes animate-slide-down {
                0% {
                    opacity: 0;
                    transform: translate(-20px,0px);
                    }
                    100% {
                        opacity: 1;
                        transform: translate(0,0);
                    }
                    
            }            
        }

        .imgK{
            width: 32px;
            margin-left: 15px;
            opacity: 0;
            height: auto;
            animation: animate-slide-up .75s linear both;
            animation-delay: 1s;
            animation-name: animate-slide-up;
            @keyframes animate-slide-up {
                0% {
                    opacity: 0;
                    transform: translate(0, -20px);
                    }
                    100% {
                        opacity: 1;
                        transform: translate(0,0);
                    }     
            }
        }
    }

    h2{
        color: #ECECEC;
        margin-top: 20px;
        font-weight: 400;
        font-size: 12px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
        overflow: visible;
    }

    .flat-button{
        color: #F5D061;
        background-color:#282F44;;
        font-size: 14;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #F5D061;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover{
            background: #F5D061;
            color: #282F44;
        }

        a{
            all: unset;
        }

    }
}

@media screen and (max-width: 1200px){
   .tags{
    display: none;
   }
   
   .home-page{
    h1{
        font-size: 4em;
    }

    .text-zone{
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        margin: 20px 10px;
        box-sizing: border-box;
    }

    .flat-button{
        float: left;
        display: block;
        margin: 20px auto 0 auto;
        width: fit-content;
    }

    .logo-container{
        position: relative;
        width: 150px;
        height: auto;
        top: 0px;
        right: 0;
        box-sizing: border-box;
        margin: 20px auto 0 50%;
        left:0;
        z-index: -1;

        svg{
            position: absolute;
            top: auto;
            right: auto;
            bottom: auto;
            left: 0;
            margin: auto;
        }
    }
   }
}