.contact-form {
    width: 100%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #b8b8b8;
      height: 50px;
      font-size: 16px;
      color: #000;
      padding: 0 20px;
      box-sizing: border-box;
      font-family: Arial, Helvetica, sans-serif;
    }
  
    textarea {
      width: 100%;
      border: 0;
      background: #b8b8b8;;
      height: 50px;
      font-size: 16px;
      color: #000;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      font-family: Arial, Helvetica, sans-serif;
    }
  
    .flat-button {
      color: #F5D061; 
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #F5D061; 
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
    }
  }
  
  .map-wrap {
    background: rgba(255, 255, 255, 0.1);
    float: right;
    width: 53%;
    height: 115%;
    margin-top: -3%;
    font-size: 20px;
    color: #000 !important;
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 30%;
    z-index: 999999; 
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
  
    span {
      font-size: 16px;
      display: block;
      padding-top: 10px;
        color: #F5D061; 
      
    }
  }

  .container.contact-page{
    
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #F5D061;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before{
        content: '<Contact>';
        font-family: 'La Belle Aurore', cursive;
        color: #F5D061;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<Me/>';
        font-family: 'La Belle Aurore', cursive;
        color: #F5D061;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: 140px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 14px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
  
  @media screen and (max-width: 1200px){
    

    .container.contact-page{
      position: initial;
      height: auto;
      min-height: auto;
      
    .text-zone{
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;

      h1{
        margin-left: 20px;
        margin-top: 20px;
      }
   }

    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 400px;
      font-size: 20px;
    }
    
    .info-map {
      display: none;
    }
  }
}


  